import Firebase from "../firebase";
import Playcanvas from "../playcanvas";
import User from "../user";
import Network from "../network";

export default class Game {
  public firebase: Firebase;
  public playcanvas: Playcanvas;
  public user: User;
  public network: Network;

  public gameSettings: any;
  public details: any;

  static dbHeartbeatFps: number = 1000 * 7;

  constructor(
    firebase: Firebase,
    playcanvas: Playcanvas,
    user: User,
    network: Network
  ) {
    this.firebase = firebase;
    this.playcanvas = playcanvas;
    this.user = user;
    this.network = network;
  }

  public async init(gameID: string) {
    this.details = await this.firebase.loadGameDetails(gameID);

    // --- events
    this.playcanvas.once("LoadGameSettings", async () => {
      // --- load default game settings
      this.gameSettings = await this.firebase.loadGameSettings();

      // --- add user details
      this.gameSettings.userID = this.user.details.uid;

      // --- add server info
      this.gameSettings.serverTime = Firebase.getServerTime();

      return this.gameSettings;
    });

    return this.details;
  }

  public async join() {
    // --- check if an existing session is open
    let session: any = await this.firebase.findOpenGameSession(
      this.details.gameID,
      this.gameSettings.maxPlayers
    );

    // --- if no session was found, create one else join
    if (session) {
      await this.firebase.joinGameSession(this.details.gameID, session);
    } else {
      session = await this.firebase.addGameSession(this.details.gameID);
    }

    // --- subscribe to session updates
    this.firebase.monitorGameSession(
      this.details.gameID,
      session,
      (data: any) => {
        this.playcanvas.sendMessage("Network:onSessionUpdate", data);
      }
    );

    // --- if there is a session to join, initialize network
    if (session) {
      this.playcanvas.sendMessage("Network:connectToSession", session);

      const audioChannelID = `session-audio-${session.sessionID}`;

      await this.network.initClient(this.user.details.uid, [
        // {
        //   channelID: `personal-${this.user.details.uid}`,
        //   personal: true,
        //   dataOrdered: false,
        //   streams: ["data"],
        //   connectionType: "p2p",
        //   connection: "p2p-0",
        // },
        // {
        //   channelID: audioChannelID,
        //   personal: false,
        //   dataOrdered: true,
        //   autoConnect: false,
        //   streams: ["data", "audio"],
        //   connectionType: "sfu",
        //   connection: "sfu-0",
        // },
        {
          channelID: `session-ordered-${session.sessionID}`,
          personal: false,
          dataOrdered: true,
          autoConnect: true,
          streams: ["data"],
          connectionType: "sfu",
          connection: "sfu-1",
        },
        {
          channelID: `session-unordered-${session.sessionID}`,
          personal: false,
          dataOrdered: false,
          autoConnect: true,
          streams: ["data"],
          connectionType: "sfu",
          connection: "sfu-1",
        },
      ]);

      // --- handle app wide network events
      this.playcanvas.on("Network:Session:ordered", (data: any) => {
        if (document.hidden === true) return;

        data.userID = this.user.details.uid;

        this.network.sendToDataChannel(
          `session-ordered-${session.sessionID}`,
          data
        );
      });

      this.playcanvas.on("Network:Session:unordered", (data: any) => {
        if (document.hidden === true) return;

        data.userID = this.user.details.uid;

        this.network.sendToDataChannel(
          `session-unordered-${session.sessionID}`,
          data
        );
      });

      let dbHeartbeatInterval: number;

      this.playcanvas.on("Player:onHeartbeat", (data: any) => {
        if (document.hidden === true || !this.details.gameID) return;

        // --- save heartbeat to DB every now and then
        if (!dbHeartbeatInterval) {
          this.firebase.updatePlayerSessionHeartbeat(
            this.details.gameID,
            session,
            this.user.details.uid,
            data
          );

          dbHeartbeatInterval = window.setTimeout(
            (): any => (dbHeartbeatInterval = undefined),
            Game.dbHeartbeatFps
          );
        }

        // --- broadcast to channel
        this.network.sendToDataChannel(
          `session-unordered-${session.sessionID}`,
          data
        );
      });

      this.playcanvas.on(
        "Network:Session:startListeningToPlayer",
        (data: any) => {
          this.network.connectRemoteClient(audioChannelID, data.userID);
        }
      );

      this.playcanvas.on(
        "Network:Session:stopListeningToPlayer",
        (data: any) => {
          this.network.disconnectRemoteClient(audioChannelID, data.userID);
        }
      );

      this.playcanvas.on("Network:Session:setPlayerVolume", (data: any) => {
        this.network.setConnectionAudioVolume(
          audioChannelID,
          data.userID,
          data.volume
        );
      });
    }
  }
}
