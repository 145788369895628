import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import Firebase, { FirebaseContext } from "./providers/firebase";
import Playcanvas, { PlaycanvasContext } from "./providers/playcanvas";
import Network, { NetworkContext } from "./providers/network";
import User, { UserContext } from "./providers/user";
import Game, { GameContext } from "./providers/game";

// --- providers
const FirebaseInstance = new Firebase();
const PlaycanvasInstance = new Playcanvas();
const NetworkInstance = new Network(PlaycanvasInstance);
const UserInstance = new User(FirebaseInstance, PlaycanvasInstance);
const GameInstance = new Game(
  FirebaseInstance,
  PlaycanvasInstance,
  UserInstance,
  NetworkInstance
);

ReactDOM.render(
  <PlaycanvasContext.Provider value={PlaycanvasInstance}>
    <FirebaseContext.Provider value={FirebaseInstance}>
      <NetworkContext.Provider value={NetworkInstance}>
        <UserContext.Provider value={UserInstance}>
          <GameContext.Provider value={GameInstance}>
            <div className="iframe-container">
              <iframe
                ref={PlaycanvasInstance.iframeRef}
                id="game-iframe"
                className="game-iframe"
              ></iframe>
            </div>
            <App />
          </GameContext.Provider>
        </UserContext.Provider>
      </NetworkContext.Provider>
    </FirebaseContext.Provider>
  </PlaycanvasContext.Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
