import Playcanvas from "../playcanvas";
import Compress from "../network/compress";

declare var window: any;

export default class Network {
  private playcanvas: Playcanvas;

  private client: fm.liveswitch.Client;
  private userID: string;
  private localMedia: fm.liveswitch.LocalMedia;

  private dataChannels: any;
  private clientChannel: fm.liveswitch.Channel[];

  private peerConnections: fm.liveswitch.PeerConnection[];
  private remoteConnectionsPending: any;

  private unRegistering: boolean = false;
  private reRegisterBackoff: number = 200;
  private maxRegisterBackoff: number = 60000;

  // --- temp data, they will be moved to a backend service
  private liveSwitchGatewayUrl: string = "https://cloud.liveswitch.io";
  private liveSwitchAppID: string = "fc81aaae-a370-413e-8c1a-285240c71bd7";
  private liveSwitchSharedSecret: string =
    "4e386daddb4142719ed007e92a544ed21fce08e9c8234c498fe01cae04b4ee44";

  constructor(playcanvas: Playcanvas) {
    this.playcanvas = playcanvas;

    window.onbeforeunload = () => {
      this.disconnect();
    };
  }

  public async initClient(userID: string, channelsToJoin: any) {
    if (!this.client) {
      // --- create client
      this.client = new fm.liveswitch.Client(
        this.liveSwitchGatewayUrl,
        this.liveSwitchAppID,
        userID
      );

      this.userID = userID;
      this.remoteConnectionsPending = {};

      console.log("Client User ID: ", this.client.getUserId());

      // --- channels to claim or join
      const claims: fm.liveswitch.ChannelClaim[] = [];

      // --- group channels by connection type/ID
      const connections: any = channelsToJoin.reduce(
        (connections: any, item: any) => {
          const connection = connections[item.connection] || [];
          connection.push(item);
          connections[item.connection] = connection;
          return connections;
        },
        {}
      );

      for (const connectionCode in connections) {
        claims.push(
          new fm.liveswitch.ChannelClaim(
            connections[connectionCode][0].channelID
          )
        );
      }

      // --- get access token
      const token = await this.generateRegisterToken(claims);

      // --- register client events
      this.client.addOnStateChange((client) => {
        if (client.getState() === fm.liveswitch.ClientState.Registering) {
          //console.log("client is registering");
        } else if (client.getState() === fm.liveswitch.ClientState.Registered) {
          //console.log("client is registered");
        } else if (
          client.getState() === fm.liveswitch.ClientState.Unregistering
        ) {
          //console.log("client is unregistering");
        } else if (
          client.getState() === fm.liveswitch.ClientState.Unregistered
        ) {
          //console.log("client is unregistered");

          // Client has failed for some reason:
          // We do not need to `c.closeAll()` as the client handled this for us as part of unregistering.
          if (!this.unRegistering) {
            setTimeout(async () => {
              // Back off our reregister attempts as they continue to fail to avoid runaway process.
              if (this.reRegisterBackoff < this.maxRegisterBackoff) {
                this.reRegisterBackoff += this.reRegisterBackoff;
              }

              // ReRegister
              const token = await this.generateRegisterToken(claims);

              this.client.register(token).then(
                (channels: fm.liveswitch.Channel[]) => {
                  this.reRegisterBackoff = 200; // reset for next time
                  this.onClientRegistered(channels, connections);
                },
                (ex: any) => {
                  console.log("Failed to register with Gateway.");
                }
              );
            }, this.reRegisterBackoff);
          }
        }
      });

      try {
        const channels: fm.liveswitch.Channel[] = await this.client.register(
          token
        );

        this.onClientRegistered(channels, connections);
      } catch (error) {
        console.log(error);
      }

      // --- TODO playcanvas events
    }
  }

  private async onClientRegistered(
    channelsClaimed: fm.liveswitch.Channel[],
    connections: any
  ) {
    this.dataChannels = {};
    this.peerConnections = [];

    for (const connectionCode in connections) {
      // --- handle each connection type
      const connectionType = connectionCode.split("-")[0];
      const connectionInfos = connections[connectionCode];

      switch (connectionType) {
        case "sfu":
          this.openClientConnection(channelsClaimed, connectionInfos);
          break;
        case "mcu":
          this.openClientConnection(channelsClaimed, connectionInfos);
          break;
        case "p2p":
          // --- find base channel claimed for 0 index
          const baseChannel = channelsClaimed.find(
            (channelClaimed: fm.liveswitch.Channel) => {
              return channelClaimed.getId() === connectionInfos[0].channelID;
            }
          );

          // baseChannel.addOnRemoteClientJoin(
          //   (remoteClientInfo: fm.liveswitch.ClientInfo) => {
          //     console.log("--- client joined", remoteClientInfo);
          //     this.openClientConnection(
          //       channelsClaimed,
          //       connectionInfos,
          //       remoteClientInfo
          //     );
          //   }
          // );

          baseChannel.addOnPeerConnectionOffer(
            (peerConnectionOffer: fm.liveswitch.PeerConnectionOffer) => {
              // Accept the peer connection offer.
              this.openClientConnection(
                channelsClaimed,
                connectionInfos,
                peerConnectionOffer
              );
            }
          );

          // Open a peer connection for each remote client.
          for (let remoteClientInfo of baseChannel.getRemoteClientInfos()) {
            if (remoteClientInfo.getUserId() === this.client.getUserId())
              continue;

            this.openClientConnection(
              channelsClaimed,
              connectionInfos,
              remoteClientInfo
            );
          }

          break;
      }
    }

    // --- temp testing ---
    window.sendToChannel = this.sendToDataChannel.bind(this);

    window.sendToChannelTest = async (channel: string, message: string) => {
      const delay = (ms: number) =>
        new Promise((resolve) => setTimeout(resolve, ms));

      for (let i = 0; i < 1000; i++) {
        window.sendToChannel(channel, message);
        await delay(50);
      }
    };
  }

  private openClientConnection(
    channelsClaimed: fm.liveswitch.Channel[],
    connectionInfos: any,
    connectionRemoteClientInfo?: any
  ) {
    const connectionType = connectionInfos[0].connectionType;
    const upstream = connectionRemoteClientInfo ? false : true;

    // --- find base channel claimed for 0 index
    let baseChannel: fm.liveswitch.Channel = channelsClaimed.find(
      (channelClaimed: fm.liveswitch.Channel) => {
        return channelClaimed.getId() === connectionInfos[0].channelID;
      }
    );

    // --- iterate through all requested channels, add and setup streams
    const dataChannels: any[] = [];

    for (const connectionInfo of connectionInfos) {
      // --- data stream
      if (connectionInfo.streams.indexOf("data") > -1) {
        const dataChannel: fm.liveswitch.DataChannel = new fm.liveswitch.DataChannel(
          connectionInfo.channelID,
          connectionInfo.dataOrdered
        );

        this.playcanvas.sendMessage(
          `Network:connectToChannel`,
          connectionInfo.channelID
        );

        dataChannel.setOnReceive(
          (args: fm.liveswitch.DataChannelReceiveArgs) => {
            if (args.getDataString != null) {
              let data: any = args.getDataString();
              try {
                data = Compress.decode(JSON.parse(data));

                // --- we don't act on messages sent by this client
                if (data.userID && data.userID === this.userID) return;

                this.playcanvas.sendMessage(
                  `Network:onMessage:${connectionInfo.channelID}`,
                  data
                );

                //console.log(data);
              } catch (error) {
                console.log(data);
              }
            }
          }
        );

        dataChannels.push(dataChannel);
      }
    }

    const dataStream = new fm.liveswitch.DataStream(dataChannels);
    let audioStream: fm.liveswitch.AudioStream;

    // --- audio stream
    if (
      connectionInfos.filter(
        (connectionInfo: any) => connectionInfo.streams.indexOf("audio") > -1
      ).length > 0
    ) {
      // --- make sure local media have started
      if (!this.localMedia) {
        this.startLocalMedia(true, false);
      }

      // Create remote media to manage incoming media.
      const remoteMedia = new fm.liveswitch.RemoteMedia();
      remoteMedia.setAudioMuted(false);

      audioStream = new fm.liveswitch.AudioStream(this.localMedia, remoteMedia);
    }

    let connection: any;

    switch (connectionType) {
      case "mcu":
        connection = baseChannel.createMcuConnection(audioStream, dataStream);
        break;
      case "sfu":
        if (upstream === true) {
          connection = baseChannel.createSfuUpstreamConnection(
            audioStream,
            dataStream
          );

          // Monitor the channel remote upstream connection changes.
          baseChannel.addOnRemoteUpstreamConnectionOpen(
            (remoteConnectionInfo: fm.liveswitch.ConnectionInfo) => {
              if (remoteConnectionInfo.getUserId() === this.client.getUserId())
                return;

              // prettier-ignore
              // console.log('Remote client opened upstream connection (connection ID: ' +
              //     remoteConnectionInfo.getId() + ', client ID: ' + remoteConnectionInfo.getClientId() + ', device ID: ' +
              //     remoteConnectionInfo.getDeviceId() + ', user ID: ' + remoteConnectionInfo.getUserId() + ', tag: ' +
              //     remoteConnectionInfo.getTag() + ').');

              if (connectionInfos[0].autoConnect === true) {
                // Open downstream connection to receive the new upstream connection.
                this.openClientConnection(
                  channelsClaimed,
                  connectionInfos,
                  remoteConnectionInfo
                );
              }else{
                // we keep the connection info to connect later on request
                const pendingInfo: any = {
                  channelsClaimed: channelsClaimed,
                  connectionInfos: connectionInfos,
                  remoteConnectionInfo: remoteConnectionInfo,
                  connection: undefined
                }

                const remoteUserID = remoteConnectionInfo.getUserId();

                // we save the info per channel
                for (const connectionInfo of connectionInfos) {
                  if( !this.remoteConnectionsPending[connectionInfo.channelID] ){
                    this.remoteConnectionsPending[connectionInfo.channelID] = {};
                  }

                  this.remoteConnectionsPending[connectionInfo.channelID][remoteUserID] = pendingInfo;
                }
              }
            }
          );

          // Open a downstream SFU connection for each remote upstream connection.
          for (let remoteConnectionInfo of baseChannel.getRemoteUpstreamConnectionInfos()) {
            this.openClientConnection(
              channelsClaimed,
              connectionInfos,
              remoteConnectionInfo
            );
          }
        } else {
          connection = baseChannel.createSfuDownstreamConnection(
            connectionRemoteClientInfo,
            audioStream,
            dataStream
          );
        }
        break;
      case "p2p":
        connection = baseChannel.createPeerConnection(
          connectionRemoteClientInfo,
          audioStream,
          dataStream
        );
        break;
    }

    // Monitor the connection state changes.
    connection.addOnStateChange((connection: any) => {
      // console.log(
      //   connection.getId() +
      //     ": Central Data connection state is " +
      //     new fm.liveswitch.ConnectionStateWrapper(
      //       connection.getState()
      //     ).toString() +
      //     "."
      // );

      // Cleanup if the connection closes or fails.
      if (
        connection.getState() === fm.liveswitch.ConnectionState.Closing ||
        connection.getState() === fm.liveswitch.ConnectionState.Failing
      ) {
      } else if (
        connection.getState() === fm.liveswitch.ConnectionState.Failed
      ) {
        // Note: no need to close the connection as it's done for us.
        this.openClientConnection(
          channelsClaimed,
          connectionInfos,
          connectionRemoteClientInfo
        );
      } else if (
        connection.getState() === fm.liveswitch.ConnectionState.Connected
      ) {
        // if connectio was successful then we can use this data channel
        for (let index = 0; index < connectionInfos.length; index++) {
          const connectionInfo = connectionInfos[index];

          if (
            connectionType === "mcu" ||
            (connectionType === "sfu" && upstream) ||
            connectionType === "p2p"
          ) {
            this.dataChannels[connectionInfo.channelID] = dataChannels[index];
          }
        }

        //console.log(connectionType, "dataChannels", this.dataChannels);
      }
    });

    connection.open();

    if (connectionType === "p2p") {
      this.peerConnections.push(connection);
    }

    return connection;
  }

  private async disconnect() {
    if (this.client != null) {
      await this.client.unregister();
    }
  }

  public connectRemoteClient(channelID: string, userID: string) {
    const pendingClientInfo = this.remoteConnectionsPending[channelID][userID];

    if (pendingClientInfo) {
      //console.log("pending", pendingClientInfo);

      // Open downstream connection to receive the pending upstream connection.
      const connection = this.openClientConnection(
        pendingClientInfo.channelsClaimed,
        pendingClientInfo.connectionInfos,
        pendingClientInfo.remoteConnectionInfo
      );

      pendingClientInfo.connection = connection;
    }
  }

  public disconnectRemoteClient(channelID: string, userID: string) {
    const pendingClientInfo = this.remoteConnectionsPending[channelID][userID];

    if (pendingClientInfo && pendingClientInfo.connection) {
      //console.log("disconnect", userID);
      pendingClientInfo.connection.close();
    }
  }

  public setConnectionAudioVolume(
    channelID: string,
    userID: string,
    volume: number
  ) {
    const pendingClientInfo = this.remoteConnectionsPending[channelID][userID];

    if (pendingClientInfo && pendingClientInfo.connection) {
      //console.log("volume", volume);

      const connection: fm.liveswitch.Connection = pendingClientInfo.connection;

      const audioTrack: fm.liveswitch.AudioTrack = connection
        .getAudioStream()
        .getRemoteTrack();

      if (audioTrack) {
        audioTrack.setVolume(volume);
      }
    }
  }

  public sendToDataChannel = (channel: string, message: object | string) => {
    const dataChannel: fm.liveswitch.DataChannel = this.dataChannels[channel];

    if (!dataChannel) return;

    try {
      if (typeof message === "object") {
        // @ts-ignore
        message.userID = this.userID;
      }

      const data =
        typeof message === "string"
          ? message
          : JSON.stringify(Compress.encode(message));

      dataChannel.sendDataString(data);
    } catch (error) {
      console.log("error sending message", message, channel);
    }
  };

  public async joinPeerChannel(remoteConnectionInfo: any) {
    const joinToken = this.generateJoinToken(remoteConnectionInfo.channelID);

    const remoteChannel: fm.liveswitch.Channel = await this.client.join(
      remoteConnectionInfo.channelID,
      joinToken
    );

    remoteChannel.addOnPeerConnectionOffer(
      (peerConnectionOffer: fm.liveswitch.PeerConnectionOffer) => {
        // Accept the peer connection offer.
        this.openClientConnection(
          [remoteChannel],
          [remoteConnectionInfo],
          peerConnectionOffer
        );
      }
    );
  }

  public async leaveChannel(channelID: string) {
    if (!this.client) return false;

    await this.client.leave(channelID);
  }

  private async startLocalMedia(audio: any, video: any) {
    try {
      this.localMedia = new fm.liveswitch.LocalMedia(audio, video);

      await this.localMedia.start();
    } catch (error) {}
  }

  public async setPeerChannelVolume(userID: any, volume: number) {
    for (const connectionID in this.peerConnections) {
      const connection: fm.liveswitch.PeerConnection = this.peerConnections[
        connectionID
      ];

      const remoteClientInfo: fm.liveswitch.ClientInfo = connection.getRemoteClientInfo();

      if (remoteClientInfo.getUserId() !== userID) return;

      const audioTrack: fm.liveswitch.AudioTrack = connection
        .getAudioStream()
        .getRemoteTrack();
      audioTrack.setVolume(volume);
    }
  }

  private async stopLocalMedia() {
    try {
      await this.localMedia.stop();
    } catch (error) {}
  }

  private generateRegisterToken(claims: fm.liveswitch.ChannelClaim[]) {
    return fm.liveswitch.Token.generateClientRegisterToken(
      this.liveSwitchAppID,
      this.client.getUserId(),
      this.client.getDeviceId(),
      this.client.getId(),
      null,
      claims,
      this.liveSwitchSharedSecret
    );
  }

  private generateJoinToken(channelId: string) {
    return fm.liveswitch.Token.generateClientJoinToken(
      this.liveSwitchAppID,
      this.client.getUserId(),
      this.client.getDeviceId(),
      this.client.getId(),
      new fm.liveswitch.ChannelClaim(channelId),
      this.liveSwitchSharedSecret
    );
  }
}
