import React, { useContext, useState, useEffect } from "react";
import { Redirect, Route } from "react-router-dom";
import { IonApp, IonRouterOutlet, IonLoading } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";

import GamePage from "./pages/Game/GamePage";
import StartPage from "./pages/Start/StartPage";
import LoginPage from "./pages/Login/LoginPage";

import User, { UserContext, PrivateRoute } from "./providers/user";
import Firebase, { FirebaseContext } from "./providers/firebase";

import * as routes from "./constants/routes";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";

const App: React.FC = () => {
  // --- contexts
  const firebase: Firebase = useContext(FirebaseContext);
  const user: User = useContext(UserContext);

  // --- states
  const [dbReady, setDBReady] = useState(false);

  // --- useEffect
  useEffect(() => {
    firebase.init().then((authResult: any) => {
      user.init(authResult);

      setDBReady(true);
    });
  }, []);

  return dbReady === false ? (
    <div>
      <IonLoading isOpen={true} message={"Loading DB..."} duration={0} />
    </div>
  ) : (
    <IonApp>
      <IonReactRouter>
        <IonRouterOutlet>
          <Route path={routes.START} component={StartPage} />
          <Route path={routes.LOGIN} component={LoginPage} />
          <PrivateRoute path={routes.GAME} component={GamePage} exact={true} />
          <Route exact path="/" render={() => <Redirect to="/world/1" />} />
        </IonRouterOutlet>
      </IonReactRouter>
    </IonApp>
  );
};

export default App;
