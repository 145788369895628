import React from "react";

interface IEventHandler {
  eventType: string;
  callback: Function;
  context?: any;
  once: boolean;
}

export default class Playcanvas {
  public iframeRef: React.RefObject<HTMLIFrameElement>;

  private iframeReady: boolean;
  private messagesQueue: any[];

  private eventHandlers: IEventHandler[];

  private handlingEvents: boolean;
  private eventHandlers_New: IEventHandler[];

  constructor() {
    this.iframeRef = React.createRef();

    this.iframeReady = false;
    this.messagesQueue = [];
    this.eventHandlers = [];

    this.handlingEvents = false;
  }

  connectToIframe(src: string) {
    this.iframeRef.current.src = src;

    // --- postMessage event handlers
    window.addEventListener("message", this.parseMessage.bind(this));
  }

  parseMessage(event: any) {
    if (!event.data || !event.data.type) return;

    switch (event.data.type) {
      case "IFrameReady":
        this.iframeReady = true;

        // --- send queue messages
        this.messagesQueue.forEach((message) => {
          this.sendMessage(message.type, message.data);
        });
        break;
    }

    this.handleEvents(event.data);
  }

  handleEvents(eventData: any) {
    const id: number = eventData.id;
    const type: string = eventData.type;
    const data: string = eventData.data;
    const waitForReply: boolean = eventData.waitForReply;

    const toKeep: IEventHandler[] = [];

    this.handlingEvents = true;
    this.eventHandlers_New = [];

    this.eventHandlers.forEach(async (event) => {
      if (event.eventType === type) {
        const result = await event.callback.call(event.context, data);

        // --- check if caller waits for a reply
        if (waitForReply) {
          this.sendMessage(type, result, id);
        }

        if (event.once !== true) toKeep.push(event);
      } else {
        toKeep.push(event);
      }
    });

    this.handlingEvents = false;

    this.eventHandlers = toKeep;

    // --- add event hanlders attached during the handle events run
    this.eventHandlers_New.forEach((event) => {
      this.eventHandlers.push(event);
    });
  }

  on(type: string, callback: Function, context?: any) {
    const event: IEventHandler = {
      eventType: type,
      callback: callback,
      context: context,
      once: false,
    };

    this.eventHandlers.push(event);

    if (this.handlingEvents) {
      this.eventHandlers_New.push(event);
    }
  }

  once(type: string, callback: Function, context?: any) {
    const event: IEventHandler = {
      eventType: type,
      callback: callback,
      context: context,
      once: true,
    };

    this.eventHandlers.push(event);

    if (this.handlingEvents) {
      this.eventHandlers_New.push(event);
    }
  }

  sendMessage(type: string, data: any = null, id?: number) {
    // --- handle in this context messaging
    // this.handleEvents(type, data);

    const message = {
      id: id ? id : Date.now(),
      type: type,
      data: data,
    };

    // --- if we aren't ready, queue messages for sending later
    if (this.iframeReady === false) {
      this.messagesQueue.push(message);
      return;
    }

    this.iframeRef.current.contentWindow.postMessage(message, "*");
  }
}
