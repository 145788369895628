enum MessageType {
  onPlayerHeartbeat = 1,
  onBodyUpdate,
  onBodyUpdateMaster,
}

export default class Compress {
  constructor() {}

  public static encode(message: any) {
    if (!message.type) return message;

    let msg: any;

    switch (message.type) {
      case "onPlayerHeartbeat":
        msg = [MessageType.onPlayerHeartbeat];
        msg.push(message.userID);
        msg.push(message.avatarID);

        msg.push(Compress.roundNumber(message.position.x, 1e3));
        msg.push(Compress.roundNumber(message.position.y, 1e3));
        msg.push(Compress.roundNumber(message.position.z, 1e3));

        msg.push(Compress.roundNumber(message.rotation.x, 1e3));
        msg.push(Compress.roundNumber(message.rotation.y, 1e3));
        msg.push(Compress.roundNumber(message.rotation.z, 1e3));

        msg.push(Compress.roundNumber(message.velocity.x, 1e3));
        msg.push(Compress.roundNumber(message.velocity.y, 1e3));
        msg.push(Compress.roundNumber(message.velocity.z, 1e3));

        msg.push(message.timestamp);

        return msg;

      case "onBodyUpdate":
      case "onBodyUpdateMaster":
        msg = [
          message.type === "onBodyUpdate"
            ? MessageType.onBodyUpdate
            : MessageType.onBodyUpdateMaster,
        ];
        msg.push(message.userID);

        msg.push(message.entity);
        msg.push(message.state);

        msg.push(Compress.roundNumber(message.position.x, 1e3));
        msg.push(Compress.roundNumber(message.position.y, 1e3));
        msg.push(Compress.roundNumber(message.position.z, 1e3));

        msg.push(Compress.roundNumber(message.rotation.x, 1e3));
        msg.push(Compress.roundNumber(message.rotation.y, 1e3));
        msg.push(Compress.roundNumber(message.rotation.z, 1e3));

        msg.push(Compress.roundNumber(message.velocity.x, 1e3));
        msg.push(Compress.roundNumber(message.velocity.y, 1e3));
        msg.push(Compress.roundNumber(message.velocity.z, 1e3));

        msg.push(Compress.roundNumber(message.velocityAngular.x, 1e3));
        msg.push(Compress.roundNumber(message.velocityAngular.y, 1e3));
        msg.push(Compress.roundNumber(message.velocityAngular.z, 1e3));

        msg.push(message.timestamp);

        return msg;

      default:
        return message;
    }
  }

  public static decode(message: any) {
    const type = message[0];

    switch (type) {
      case MessageType.onPlayerHeartbeat:
        return {
          type: MessageType[type],
          userID: message[1],
          avatarID: message[2],
          position: {
            x: message[3],
            y: message[4],
            z: message[5],
          },
          rotation: {
            x: message[6],
            y: message[7],
            z: message[8],
          },
          velocity: {
            x: message[9],
            y: message[10],
            z: message[11],
          },
          timestamp: message[12],
        };

      case MessageType.onBodyUpdate:
      case MessageType.onBodyUpdateMaster:
        return {
          type: MessageType[type],
          userID: message[1],
          entity: message[2],
          state: message[3],
          position: {
            x: message[4],
            y: message[5],
            z: message[6],
          },
          rotation: {
            x: message[7],
            y: message[8],
            z: message[9],
          },
          velocity: {
            x: message[10],
            y: message[11],
            z: message[12],
          },
          velocityAngular: {
            x: message[13],
            y: message[14],
            z: message[15],
          },
          timestamp: message[16],
        };

      default:
        return message;
    }
  }

  private static roundNumber(x: number, base: number) {
    // base can be 1e3, 1e3 etc
    return Math.round(x * base) / base;
  }
}
