import React, { useContext, useEffect, useState } from "react";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonLoading,
} from "@ionic/react";
import { useParams } from "react-router-dom";

import Playcanvas, { PlaycanvasContext } from "../../providers/playcanvas";
import Game, { GameContext } from "../../providers/game";

import "./GamePage.css";

const GamePage: React.FC = () => {
  // --- contexts
  const playcanvas: Playcanvas = useContext(PlaycanvasContext);
  const game: Game = useContext(GameContext);

  // --- params
  const { gameID } = useParams();

  // --- states
  const [gameReady, setGameReady] = useState(false);

  // --- useEffect
  useEffect(() => {
    game.init(gameID).then(async (gameDetails: any) => {
      playcanvas.on("Uranus:GameReady", () => {
        game.join();

        setGameReady(true);
      });

      const iframeUrl: string =
        new URL(window.location.href).searchParams.get("debug") === "true"
          ? `https://launch.playcanvas.com/${gameDetails.sceneID}?debug=true`
          : `https://playcanv.as/index/${gameDetails.buildID}`;

      playcanvas.connectToIframe(iframeUrl);
    });
  }, []);

  return gameReady === false ? (
    <div>
      <IonLoading isOpen={true} message={"Loading Game..."} duration={0} />
    </div>
  ) : (
    <IonPage className="disable-pointer-events">
      <IonHeader>
        <IonToolbar>
          <IonTitle>Game {gameID}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="transparent-content-background"></IonContent>
    </IonPage>
  );
};

export default GamePage;
