import Firebase from "../firebase";
import Playcanvas from "../playcanvas";
import { auth } from "firebase";

export default class User {
  public firebase: Firebase;
  public playcanvas: Playcanvas;

  public details: firebase.User;

  constructor(firebase: Firebase, playcanvas: Playcanvas) {
    this.firebase = firebase;
    this.playcanvas = playcanvas;
  }

  public isLoggedIn() {
    return this.firebase.user !== null;
  }

  public init(authResult: any) {
    if (authResult) {
      this.details = authResult.user ? authResult.user : authResult;
    }
  }
}
