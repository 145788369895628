import React, { useContext, useEffect } from "react";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";

import Firebase, { FirebaseContext } from "../../providers/firebase";
import User, { UserContext } from "../../providers/user";

import * as routes from "../../constants/routes";

import "./LoginPage.css";

const LoginPage: React.FC = (props: any) => {
  // --- contexts
  const firebase: Firebase = useContext(FirebaseContext);
  const user: User = useContext(UserContext);

  // --- useEffect
  useEffect(() => {
    if (user.isLoggedIn() === true) {
      props.history.replace("world/1", "none");
    } else {
      firebase.initAuthUI().then((authResult: any) => {
        user.init(authResult);

        props.history.replace("world/1", "none");
      });
    }
  }, []);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Login</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="firebase-ui" ref={firebase.uiElementRef}></div>
      </IonContent>
    </IonPage>
  );
};

export default LoginPage;
