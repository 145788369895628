import React from "react";

import * as firebase from "firebase/app";
import * as firebaseui from "firebaseui";
import "firebase/auth";
import "firebase/firestore";
import "firebase/database";

const config = {
  apiKey: "AIzaSyBVn8p55SmiGPsE1Hp00l32E_7IlsHUn54",
  authDomain: "superfiction-b8aff.firebaseapp.com",
  databaseURL: "https://superfiction-b8aff.firebaseio.com",
  projectId: "superfiction-b8aff",
  storageBucket: "superfiction-b8aff.appspot.com",
  messagingSenderId: "198333919634",
  appId: "1:198333919634:web:4c7046bb544135e482914b",
  measurementId: "G-CCSNG47FG8",
};

let uiSignInPromise: any;

const uiConfig: firebaseui.auth.Config = {
  signInFlow: "popup",
  callbacks: {
    // Avoid redirects after sign-in.
    signInSuccessWithAuthResult: (authResult: any) => {
      uiSignInPromise(authResult);

      return false;
    },
  },
  // We will display Google and Facebook as auth providers.
  signInOptions: [
    firebase.auth.EmailAuthProvider.PROVIDER_ID,
    firebase.auth.PhoneAuthProvider.PROVIDER_ID,
  ],
};

class Firebase {
  public auth: firebase.auth.Auth;
  public firestore: firebase.firestore.Firestore;

  public uiElementRef: React.RefObject<HTMLDivElement>;

  public user: firebase.User;

  constructor() {
    firebase.initializeApp(config);

    this.auth = firebase.auth();
    this.firestore = firebase.firestore();

    this.uiElementRef = React.createRef();

    // @ts-ignore
    window.auth = this.auth;
  }

  static getServerTime() {
    return firebase.firestore.Timestamp.now().toDate().getTime();
  }

  public init() {
    return new Promise((resolve) => {
      // debug signOut
      //this.auth.signOut();

      // --- events
      let firstTimeAuth = false;
      this.auth.onAuthStateChanged((authUser) => {
        console.log("onAuthStateChanged", authUser ? authUser.uid : null);
        this.user = authUser;

        if (firstTimeAuth === false) {
          firstTimeAuth = true;
          resolve(authUser);
        }
      });
    });
  }

  public initAuthUI() {
    return new Promise((resolve: any) => {
      const ui = new firebaseui.auth.AuthUI(this.auth);

      ui.start(this.uiElementRef.current, uiConfig);

      uiSignInPromise = resolve;
    });
  }

  async loadGameDetails(gameID: string) {
    const data = (
      await this.firestore.collection("games").doc(gameID).get()
    ).data();
    data.gameID = gameID;

    return data;
  }

  async findOpenGameSession(gameID: string, maxPlayers: number) {
    const query = await this.firestore
      .collection("games")
      .doc(gameID)
      .collection("sessions")
      .get();

    if (query.docs.length > 0) {
      // --- find an empty session, if it exists
      let sessionFound;

      for (const doc of query.docs) {
        const session = doc.data();
        session.sessionID = doc.id;

        const players = session.players;

        // --- check there is space in this session or we are already signed in
        if (
          players.indexOf(this.user.uid) > -1 ||
          players.length <= maxPlayers
        ) {
          sessionFound = session;
          break;
        }
      }

      if (sessionFound) {
        sessionFound.timestampCreated = sessionFound.timestampCreated
          .toDate()
          .getTime();
        sessionFound.timestamp = sessionFound.timestamp.toDate().getTime();
      }

      return sessionFound;
    } else {
      return;
    }
  }

  async addGameSession(gameID: string) {
    const doc: any = await (
      await this.firestore
        .collection("games")
        .doc(gameID)
        .collection("sessions")
        .add({
          timestampCreated: firebase.firestore.FieldValue.serverTimestamp(),
          timestamp: firebase.firestore.FieldValue.serverTimestamp(),
          players: [this.user.uid],
        })
    ).get();

    const session: any = doc.data();
    session.sessionID = doc.id;
    session.timestampCreated = session.timestampCreated.toDate().getTime();
    session.timestamp = session.timestamp.toDate().getTime();

    return session;
  }

  joinGameSession(gameID: string, session: any) {
    if (session.players.indexOf(this.user.uid) === -1) {
      session.players.push(this.user.uid);
    } else {
      return;
    }

    return this.firestore
      .collection("games")
      .doc(gameID)
      .collection("sessions")
      .doc(session.sessionID)
      .update({
        players: session.players,
      });
  }

  monitorGameSession(gameID: string, session: any, callback: Function) {
    this.firestore
      .collection("games")
      .doc(gameID)
      .collection("sessions")
      .doc(session.sessionID)
      .collection("heartbeats")
      .onSnapshot(
        {
          // Listen for document metadata changes
          includeMetadataChanges: true,
        },
        async (heartbeatsSnapshot) => {
          if (heartbeatsSnapshot.metadata.hasPendingWrites === true) return;

          const sessionData: any = (
            await this.firestore
              .collection("games")
              .doc(gameID)
              .collection("sessions")
              .doc(session.sessionID)
              .get()
          ).data();

          sessionData.heartbeats = {};

          heartbeatsSnapshot.forEach((doc: any) => {
            const heartbeat = doc.data();
            heartbeat.timestamp = heartbeat.timestamp.toDate().getTime();

            sessionData.heartbeats[doc.id] = heartbeat;
          });

          callback(sessionData);
        }
      );
  }

  async updatePlayerSessionHeartbeat(
    gameID: string,
    session: any,
    userID: string,
    data: any
  ) {
    this.firestore
      .collection("games")
      .doc(gameID)
      .collection("sessions")
      .doc(session.sessionID)
      .update({
        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      });

    return this.firestore
      .collection("games")
      .doc(gameID)
      .collection("sessions")
      .doc(session.sessionID)
      .collection("heartbeats")
      .doc(userID)
      .set({
        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        // posX: Firebase.roundNumber(data.position.x, 1e2),
        // posY: Firebase.roundNumber(data.position.y, 1e2),
        // posZ: Firebase.roundNumber(data.position.z, 1e2),
      });
  }

  async loadGameSettings() {
    return (
      await this.firestore.collection("settings").doc("game").get()
    ).data();
  }

  static roundNumber = function (x: number, base: number) {
    // base can be 1e2, 1e3 etc
    return Math.round(x * base) / base;
  };
}

export default Firebase;
